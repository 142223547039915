//-------------------------
//--------MIXIN BREAKPOINTS
//-------------------------

// EXAMPLE
//   @include breakpoint (phone) {
//       width: 40%;
//   }
//

@mixin breakpoint($point) {
	@if $point  == desktop {
		@media (min-width: $desktop)							{ @content; }
	}
	@else if $point == toDesktop {
		@media (max-width: $toDesktop)							{ @content; }
	}
	@else if $point == tablet {
		@media (min-width: $phone) and (max-width: $toDesktop)	{ @content; }
	}
	@else if $point == phone {
		@media (max-width: $phone)								{ @content; }
	}
	@else if $point == phone {
		@media (max-width: $phone)								{ @content; }
	}
	 @else if $point == devices {
        @media (max-width: $tablet)                              { @content; }
    }
}
