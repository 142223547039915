///------------------------------------
//    #SETTINGS
//------------------------------------

// Globaly available variables





//-------------------------
//    #Paths
//    Global default settings
//	  for paths
//-------------------------



$fontBaseUrl   : "http://static1.webedia.fr/fonts/";
$localFontBase : "/_common/fonts";
$imgBaseUrl    : "/_common/images";
