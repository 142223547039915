///------------------------------------
//    #BOX-SIZING
//------------------------------------

// More sensible default box-sizing:
// css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice

html {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
        box-sizing: border-box;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
}
