///------------------------------------
//    #SETTINGS
//------------------------------------

// Globaly available classes





//-------------------------
//    Global default settings
//	  for ceratin classes
//-------------------------


// an example

// .article-title {
// 	color: $black;
// 	z-index: 4;
// 	position: relative;
// }
