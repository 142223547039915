///------------------------------------
//    #SETTINGS
//------------------------------------

// Globaly available variables





//-------------------------
//    #Breakpoints
//    Global default settings
//	  for breakpointa
//-------------------------


// Look for lib/mixin/breakpoints for variable name
$toDesktop 	  : 1026px;
$desktop 	  : 1025px;
$tablet 	  : 768px;
$phone        : 767px;
