///------------------------------------
//    #SETTINGS
//------------------------------------

// Globaly available variables





//-------------------------
//    #Wrapper
//    Global default settings
//	  for wrappers
//-------------------------
body{
  background: url('../img/layer-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
