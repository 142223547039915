///------------------------------------
//    #SETTINGS
//------------------------------------

// Globaly available variables





//-------------------------
//    #Colors
//    Global default settings
//	  for colors
//-------------------------


$black: #000;
$white: #fff;
