div.parallax-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
div.parallax-container div.parallax {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  font-weight: bold;
}
.parallax1 {
  img{
    width: 40%;
    margin: 0 auto;
    display: block;
    @include breakpoint (phone){
    width: 80%;
    margin: 0 auto;
    display: block;
    margin-top: 50%;
    }
  }
}
.parallax2 {
    img{
      @include breakpoint (phone){
          margin-top: -40%;
          max-width: none;
      }
    }
}
.parallax3 {
}
.info{
  font-size: 5rem;
  margin-top: -10vh;
  text-align: center;
  font-weight: normal;
  position: relative;
  padding-top: 3rem;
  z-index: 99999;
  @include breakpoint (phone){
    margin-top: -87%;
  }
  &:before{
    position: absolute;
    top: 0;
    content: '';
    width: 38%;
    height: 1px;
    background-color: #ff1150;
    display: block;
    margin: 0 auto;
    left: 0;
    right: 0;
    @include breakpoint (phone){
      width: 70%;
    }
  }
  i{
    color: #ff1150;
    margin-right: 1rem;
    font-size: 5.5rem;
    @include transition (.3s all ease-in);
  }
  .fa-facebook-square{
    margin-right: 0.5rem;
  }
  a{
    color: $white;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    padding-bottom: 0.5rem;
    &:after{
      left: 100%;
      right: 100%;
      height: 1px;
      background-color: $white;
      content: '';
      position: absolute;
      bottom: 0;

      display: inline-block;
      @include transition (.3s all ease-in);
    }
  }
}
a{
  font-family: 'Montserrat', sans-serif;
}
.first{
  @include breakpoint (phone){
    margin-bottom: 5rem;
  }
}
.first,
.second{
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  color: $white;
  @include breakpoint (phone){
    display: block;
  }
}
.second{
      position: relative;
      cursor: pointer;
      padding-bottom: 1rem;
      text-align: center;
      margin-left: 4rem;
  &:after{
    left: 100%;
    right: 100%;
    height: 1px;
    background-color: $white;
    content: '';
    bottom: 0;
    position: absolute;
    display: inline-block;
    @include transition (.3s all ease-in);
  }
}
.first:hover {
   i{
    color: $white;
    }
  > a{
    &:after{
      left: 0;
      right: 0;
    }
}
}
.second:hover{
  i{
    color: $white;
  }
    &:after{
      left: 0;
      right: 0;
    }
  }
