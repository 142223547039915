//-------------------------
//------CENTER MIXIN
//-------------------------

// EXAMPLE
//
//   @include centerer;
//

@mixin centerer {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
